import React from 'react'
import Helmet from 'react-helmet'
import {connect} from 'react-redux'
import PT from 'prop-types'
import {Toast} from 'antd-mobile'
import RRPT from 'react-router-prop-types'
import imgDownload from '../../resource/images/sd_logo.png'
import imgAndroid from './images/android.png'
import imgIos from './images/ios.png'
import './index.scss'

class Home extends React.Component {
  state = {
    contactVisible: false,
  }

  componentDidMount() {}

  hideContactAlert = () => {
    this.setState({
      contactVisible: false,
    })
  }

  showContactAlert = () => {
    this.setState({
      contactVisible: true,
    })
  }

  getYear = () => new Date().getFullYear()

  render() {
    return (
      <div className="Home">
        <Helmet>
          <title>欢迎使用语文诵读</title>
          <style>{`body{background-color: #F9F6FF;}`}</style>
        </Helmet>
        <div>
          <div className="Home__wrap">
            <div className="Home__main">
              <div className="Home__logo" />
              <div className="Home__app" />
              <div className="Home__welcome">欢迎使用语文诵读</div>
              <pre className="Home__slogan1">
                致力于语文诵读教学研究，推出适合国人的学习产品
              </pre>
              <div className="Home__downloadWrap">
                <div className="Home__qrcode">
                  <img src={imgDownload} alt="" />
                </div>
                <div>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      Toast.info('暂未开放，敬请期待')
                    }}
                    className="Home__android"
                  >
                    <img src={imgAndroid} alt="" width="23" height="26" />
                    Android下载
                  </div>
                  <div
                    role="button"
                    tabIndex="0"
                    onClick={() => {
                      Toast.info('暂未开放，敬请期待')
                    }}
                    className="Home__iOS"
                  >
                    <img src={imgIos} alt="" width="23" height="26" />
                    iOS下载
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="Home__footer">
            <div className="Home__footerBar">
              <a href="http://api.sd.huk.ltd/cfg/user_agreement_sd_intro">
                用户协议
              </a>
              <a href="http://api.sd.huk.ltd/cfg/privacy_agreement_sd_intro">
                隐私政策
              </a>
              <div
                className="Home__contact"
                onMouseLeave={this.hideContactAlert}
                onMouseEnter={this.showContactAlert}
              >
                联系我们
                {this.state.contactVisible && (
                  <div className="Home__contactWrap">
                    邮箱：wsw@qincilongzang.net<br />
                    地址：北京市通州区观音庵北街4号院2号楼8层802
                  </div>
                )}
              </div>
            </div>
            <div className="Home__companyInfo">
              {/* `北京满愿盛世文化传播有限公司 · ${this.getYear()}` */}
              {/* `东业兴吉(北京)文化传媒有限公司 · ${this.getYear()}` */}
              {`北京广诵文化传媒有限公司 · ${this.getYear()}`}
              <a
                href="https://beian.miit.gov.cn"
                target="_blank"
                rel="noopener noreferrer"
                className="Home__govInfo"
              >
                {' '}
                京ICP备14027845号-1
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Home.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  history: RRPT.history.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  userAgent: PT.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  hostname: PT.string.isRequired,
}

Home.defaultProps = {}

export default connect(state => {
  const {
    env: {userAgent, hostname},
  } = state
  // console.log('state', state)
  return {userAgent, hostname}
})(Home)
